import React from 'react'
import useSorterHelper from '../hooks/useSorterHelper'
import LayerImages from './LayerImages'

const DataRenderer = ({
    metadata
}) => {
    const {baseKitArray, extrasArray, boostsArray } = useSorterHelper(metadata);
    
  return (
        <LayerImages metadata={metadata} baseKitArray={baseKitArray} extrasArray={extrasArray} boostsArray={boostsArray} />
   )
}

export default DataRenderer