import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
// import LayerImages from './LayerImages.jsx';
// import metadata from '../metadata/metadata.json';
// import useSorterHelper from '../hooks/useSorterHelper.js';
import DataRenderer from './DataRenderer.jsx';
import config from '../config/config';

const CardRender = () => {
  const location = useLocation();
  const metadataURL = location.search.slice(1);
  const [loading, setLoading] = useState(true);
  const [metadata, setMetadata] = useState({});

  // console.log(metadata[metadataURL])
  // useEffect(() => {
  //     (async () => {
  //         axios.get(`https://${metadataURL}`).then((response) => {
  //             setmetadata(response.data);
  //             setLoading(false)
  //         }).catch((e) => {
  //             console.log(e);
  //         });
  //     })();
  // }, []);

  useEffect(() => {
    axios
      .get(`${config.METADATA_BASE_URL}/${metadataURL}.json`)
      .then((response) => {
        setMetadata(response.data);
        console.log(response)
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  console.log(metadata)
  return (
    <>
      <ClipLoader
        size={150}
        color='rgb(252, 108, 3)'
        loading={loading}
        css={{
          position: 'absolute',
          border: '5px solid rgb(252, 108, 3)',
          borderBottomColor: 'transparent',
          margin: 'auto',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
        speedMultiplier={0.5}
      />
      <div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
        {metadata && !loading && <DataRenderer metadata={metadata} />}
      </div>
    </>
  );
};

export default CardRender;
