const dev = {
    METADATA_BASE_URL: 'metadata'
};

const prod = {

};

const common = {
};

// if use npm/yarn start,  NODE_ENV = "development"
// if use npm/yarn build,  NODE_ENV = "production"
let envConfig = dev; // process.env.NODE_ENV === "development" ? dev : prod
let config = { ...envConfig, ...common };

export default config;
